<template>
  <div class="down">
    <div>
      <el-form  ref="form" :model="form" :rules="rules" label-position="left" label-width="160px" size="small">
        <el-form-item label="退回录入时间：">
          <el-date-picker
            v-model="form.backDateQuery"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss'>
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onsubmit">下 载</el-button>
    </div>
  </div>
</template>

<script>
import {find30BeforeToday} from "@/services/special";
import {getBaseURL} from '@/utils/someCommen'
export default {
  name: 'Down',
  props: {},
  created() {
    this.load30BeforeToday()
  },
  data() {
    return {
      dateList:[],
      form:{},
      // 用于设置表单校验规则
      rules: {
        date: [{required: true,trigger: 'blur',message: '下载不能为空'}]
      },
      BASE_API: getBaseURL('/auth/backNoInfo/exportData'), //获取后端接口地址
    }
  },
  methods: {
    async load30BeforeToday() {
      const {data} = await find30BeforeToday()
      this.dateList = data.data
    },

    onCancel() {
      //设置取消状态，让父组件处理
      this.$emit('cancel')
      this.form = {}
    },

    async onsubmit() {
      // 设置校验
      await this.$refs.form.validate()
      this.$emit('success')
      this.downloadExcel()
      this.form = {}
    },

    downloadExcel() {
      if (!this.form||!this.form.backDateQuery) {
        return this.$message.error("查询条件不能为空")
      }
      let dateBegin = this.form.backDateQuery[0]
      let dateEnd = this.form.backDateQuery[1]
      this.form.dateBegin = dateBegin
      this.form.dateEnd = dateEnd
      this.$message.success("正在下载，请稍后...")
      window.location.href = this.BASE_API + `/auth/backNoInfo/exportData/${this.form.dateBegin}/${this.form.dateEnd}/`

    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  //设置内部元素水平居中
  justify-content: center;
  //设置内部元素垂直居中
  align-items: center;
}
.el-form {
  width: 80%;
}
</style>
